/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./src/stylesheets/index.css";
import "intersection-observer";
import smoothscroll from "smoothscroll-polyfill";

import AOS from "aos";
import React from "react";
import { CartProvider } from "./src/contexts/CartContext";
import { ModalProvider } from "./src/contexts/ModalContext";
import { AlertProvider } from "./src/contexts/AlertContext";
import { FetchInterceptor } from "./src/utils/interceptor";

AOS.init({ once: true, duration: 1000 });
smoothscroll.polyfill();

export const wrapRootElement = ({ element }) => (
  <AlertProvider>
    <ModalProvider>
      <CartProvider>
        <FetchInterceptor>{element}</FetchInterceptor>
      </CartProvider>
    </ModalProvider>
  </AlertProvider>
);
