import React, { createContext, useReducer } from "react";

interface ModalContextProps {
  isOpen: boolean;
  content: any;
  title: string;
}

interface ModalProviderProps {
  state: ModalContextProps;
  dispatch: React.Dispatch<{
    [x: string]: any;
    type: any;
  }>;
}

interface ActionProps {
  type: string;
  [key: string]: any;
}

const initialState = {
  isOpen: false,
  content: null,
  title: null
};

const ModalContext = createContext<ModalContextProps | ModalProviderProps>(initialState);

const reducer = (state: any, action: ActionProps) => {
  switch (action.type) {
    case "showPrivacy":
      return { ...state, isOpen: true, title: "Privacy Policy", content: "policy" };
    case "showReturn":
      return { ...state, isOpen: true, title: "Return Policy", content: "return" };
    case "showTerms":
      return { ...state, isOpen: true, title: "Terms & Conditions", content: "terms" };
    case "hideModal":
      return initialState;
    default:
      return state;
  }
};

const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <ModalContext.Provider value={{ state, dispatch }}>{children}</ModalContext.Provider>;
};

export { ModalContext, ModalProvider, ModalContextProps, ModalProviderProps };
