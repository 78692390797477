const baseUrl = "https://api.getwavyblue.com";
const doRequest = (method: string, endpoint: string, body?: any) => {
  return new Promise<string>((resolve, reject) => {
    const _method = method.toUpperCase();
    const _endpoint = endpoint.charAt(0) === "/" ? endpoint.slice(1) : endpoint;
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (Math.floor(xhr.status / 100) === 2) {
          resolve(xhr.responseText);
        } else {
          reject(xhr.responseText);
        }
      }
    };

    const url = _endpoint.startsWith("https") ? _endpoint : `${baseUrl}/${_endpoint}`;
    xhr.open(_method, url, true);
    if (_method === "POST") {
      xhr.setRequestHeader("Content-type", "application/json");
      xhr.send(JSON.stringify(body));
    } else {
      xhr.send();
    }
  });
};

class HttpLib {
  static get = (url: string) => doRequest("GET", url);
  static post = (url: string, body: any) => doRequest("POST", url, body);
}

/**
 * This is the wavy api
 */
export default class WavyApi {
  /**
   * Subscribe to the newsletter
   * @param {string} email - the email that is subscribing
   * @param {string} phoneNumber - the phoneNumber that is subscribing
   */
  static subscribeToNewsletter = (email: string, phoneNumber?: string) =>
    HttpLib.post("/subscribe", { email, phoneNumber });

  /**
   * Send us a message
   * @param {string} name - user name
   * @param {string} email - user email
   * @param {string} message - the message
   */
  static contactUs = (name: string, email: string, message: string) => {
    return HttpLib.post("/contact", { name, email, message });
  };

  /**
   * Get instagram images
   */
  static getInstagramImages = () => HttpLib.get("/photos");
}
