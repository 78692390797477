import Cookies from "js-cookie";
import React, { createContext, useCallback, useEffect, useReducer } from "react";
import { getSubTotal, ShopifyClient as client } from "../utils/shopify";

interface Cart extends ShopifyBuy.Cart {
  id: string;
  webUrl: string;
}

interface LineItem extends ShopifyBuy.LineItem {
  variant?: any;
}

interface MetadataProps {
  skuVariantMap: any;
  variantSkuMap: any;
  lineItems: any;
}

interface CartContextProps {
  isOpen: boolean;
  loading: boolean;
  checkoutUrl: string;
  subTotal: number;
  cart: any;
  metadata: MetadataProps;
}

interface CartProviderProps {
  state: CartContextProps;
  dispatch: React.Dispatch<{
    [x: string]: any;
    type: any;
  }>;
}

interface ActionProps {
  type: string;
  [key: string]: any;
}

const initialState = {
  isOpen: false,
  loading: false,
  checkoutUrl: "",
  cart: {},
  subTotal: 0,
  metadata: {
    skuVariantMap: {},
    variantSkuMap: {},
    lineItems: {}
  }
};

const CartContext = createContext<CartContextProps | CartProviderProps>(initialState);

const updateCart = (
  state: any,
  sku: string,
  subTotal: number,
  value: number | undefined = undefined,
  lineItem = undefined
) => ({
  ...state,
  subTotal: subTotal,
  isOpen: window.innerWidth > 768 || state.isOpen,
  loading: false,
  cart: {
    ...state.cart,
    [sku]: value
  },
  metadata: {
    ...state.metadata,
    lineItems: {
      ...state.metadata.lineItems,
      [sku]: lineItem
    }
  }
});

const reducer = (state: any, action: ActionProps) => {
  switch (action.type) {
    case "initializeCart":
      return {
        ...state,
        cart: action.cart,
        metadata: action.metadata,
        subTotal: action.subTotal,
        checkoutUrl: action.checkoutUrl
      };
    case "toggleCart":
      return { ...state, isOpen: !state.isOpen };
    case "addToCart":
      const value = Math.max((state.cart[action.sku] || 0) + 1, 1);
      return updateCart(state, action.sku, action.subTotal, value, action.lineItem);
    case "removeFromCart":
      const result = updateCart(state, action.sku, action.subTotal);
      delete result.cart[action.sku];
      delete result.metadata.lineItems[action.sku];
      return result;
    case "updateQuantity":
      const lineItem = state.metadata.lineItems[action.sku];
      return updateCart(state, action.sku, action.subTotal, action.quantity, lineItem);
    case "setLoading":
      return { ...state, loading: action.loading === undefined ? !state.loading : action.loading };
    default:
      return state;
  }
};

// make Shopify requests before the render phase begins while passing SSR
const key = "checkout_id";
let _requests: any[] = [];
let _checkoutId: any = null;
try {
  _checkoutId = Cookies.get(key);
  _requests = [
    client.product.fetchAll(),
    _checkoutId ? client.checkout.fetch(_checkoutId) : client.checkout.create()
  ];
} catch (_error) {}

const addCoupon = async (cart: Cart, code: string | null) => {
  if (!code) {
    return cart;
  }

  try {
    return (await client.checkout.addDiscount(_checkoutId, code)) as Cart;
  } catch (error) {
    console.warn("unable to add discount code:", error);
    return cart;
  }
};

// Instantiate cart state across application
const CartProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initializeCart = useCallback(
    async (products: ShopifyBuy.Product[], shopifyCart: Cart) => {
      // if there is a coupon code, add the discount
      const params = new URLSearchParams(location.search);
      const checkout = await addCoupon(shopifyCart, params.get("coupon"));

      // create metadata
      const metadata: MetadataProps = {
        skuVariantMap: {},
        variantSkuMap: {},
        lineItems: {}
      };

      // update products
      products.forEach(product => {
        product.variants.forEach(variant => {
          if (variant.title.includes("4 oz")) {
            metadata.variantSkuMap[variant.id] = "FOUR_OZ";
            metadata.skuVariantMap.FOUR_OZ = variant.id;
          } else {
            metadata.variantSkuMap[variant.id] = "TWO_OZ";
            metadata.skuVariantMap.TWO_OZ = variant.id;
          }
        });
      });

      // create/update cart
      const cart = {};
      Cookies.set(key, checkout.id, { expires: 14 });

      checkout.lineItems.forEach((lineItem: LineItem) => {
        const sku = metadata.variantSkuMap[lineItem.variant.id];
        metadata.lineItems[sku] = lineItem.id;
        cart[sku] = lineItem.quantity;
      });

      // dispatch all changes
      dispatch({
        type: "initializeCart",
        checkoutUrl: checkout.webUrl,
        cart,
        metadata,
        subTotal: getSubTotal(checkout)
      });
    },
    [dispatch]
  );

  // initialize the cart & products
  useEffect(() => {
    // handle the requests
    Promise.all(_requests).then(([products, checkout]: any) => {
      if (!checkout || checkout.completedAt) {
        // create a new cart
        client.checkout.create().then(result => {
          _checkoutId = result.id;
          initializeCart(products, result as Cart);
        });
      } else {
        _checkoutId = checkout.id;
        initializeCart(products, checkout);
      }
    });
  }, [initializeCart]);

  // return
  return <CartContext.Provider value={{ state, dispatch }}>{children}</CartContext.Provider>;
};

export { CartContext, CartProvider, CartContextProps, CartProviderProps };
