import React, { useCallback, useContext, useEffect } from "react";
import { CartContext, CartProviderProps } from "../contexts/CartContext";

const constantMock = window.fetch;
export const FetchInterceptor = ({ children }) => {
  // hooks
  const cartContext = useContext(CartContext) as CartProviderProps;

  // variables
  const { dispatch } = cartContext;

  // functions
  const changeLoadingValue = useCallback(
    (isShopifyCall, value) => {
      if (isShopifyCall) {
        dispatch({ type: "setLoading", loading: value });
      }
    },
    [dispatch]
  );

  // update function in useEffect because of gatsby
  useEffect(() => {
    window.fetch = (...args) => {
      // inspect request to prevent loading on specific requests
      const variables = !!args[1]?.body ? JSON.parse(args[1].body as string).variables || {} : {};
      const isShopifyCall = !!(
        variables.checkoutId &&
        (variables.lineItems || variables.lineItemIds)
      );
      changeLoadingValue(isShopifyCall, true);

      return new Promise((resolve, reject) => {
        constantMock
          .apply(this, args)
          .then((response: Response | PromiseLike<Response>) => {
            changeLoadingValue(isShopifyCall, false);
            resolve(response);
          })
          .catch((error: any) => {
            changeLoadingValue(isShopifyCall, false);
            reject(error);
          });
      });
    };
  }, [changeLoadingValue]);
  return <>{children}</>;
};
