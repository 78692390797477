import React, { createContext, useCallback, useEffect, useReducer } from "react";
import WavyApi from "../utils/api";

interface AlertContextProps {
  id: number;
  duration: number;
  alertType: string;
  isOpen: boolean;
  content: any;
}

interface AlertProviderProps {
  state: AlertContextProps;
  dispatch: React.Dispatch<{
    [x: string]: any;
    type: any;
  }>;
}

interface ActionProps {
  type: string;
  [key: string]: any;
}

interface KlaviyoEvent extends Event {
  detail: {
    /**
     * This specifies if the event listener is triggered when the form is opened, closed, or submitted. The possible values are:
     *
     * `open` - When a popup or flyout form appears to the visitor.
     *
     * `embedOpen` - When an embedded form appears to the visitor.
     *
     * `close` - When a visitor closes the form
     *
     * `submit` - When a visitor submits the form
     *
     * `redirectedToUrl` - When a visitor clicks a "Go to URL" button action
     */
    type: string;
    /**
     * This is the ID of the form, which can be found in the URL of the form within Klaviyo. This is a six digit alphanumeric code and uniquely identifies each form in Klaviyo.
     */
    formId: string;
    /**
     * This category is populated with data related to each unique submit event and contains all of the fields submitted to Klaviyo.
     */
    metaData: {
      /**
       * This is the email address of the person who submits the form.
       */
      $email?: string;
      /**
       * This is the phone number of the person who submits the form.
       */
      $phone_number?: string;
    };
  };
}

const initialState = {
  id: 0,
  duration: 3000,
  alertType: "info",
  isOpen: false,
  content: null
};

const AlertContext = createContext<AlertContextProps | AlertProviderProps>(initialState);

const reducer = (state: any, { type, ...action }: ActionProps) => {
  switch (type) {
    case "showAlert":
      return { ...state, isOpen: true, id: Math.random(), ...action };
    case "hideAlert":
      return initialState;
    default:
      return state;
  }
};

const AlertProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // since adding to the form can take a while,
  // let's manually submit using the api
  const onSubmit = useCallback((event: Event) => {
    const e = event as KlaviyoEvent;
    if (e.detail.type === "submit") {
      const { $email: email, $phone_number: phoneNumber } = e.detail.metaData;
      WavyApi.subscribeToNewsletter(email, phoneNumber);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("klaviyoForms", onSubmit);
  }, [onSubmit]);

  return <AlertContext.Provider value={{ state, dispatch }}>{children}</AlertContext.Provider>;
};

export { AlertContext, AlertProvider, AlertContextProps, AlertProviderProps };
