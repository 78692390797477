import Cookies from "js-cookie";
import Client from "shopify-buy";

let _checkoutId = "";
const _setCheckoutId = () => {
  if (window.localStorage && !_checkoutId) {
    _checkoutId = Cookies.get("checkout_id") || "";
  }
};

export const ShopifyClient = Client.buildClient({
  domain: "wavy-formula.myshopify.com",
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_TOKEN as string
});

export const addToCart = (payload: Client.LineItemToAdd[]) => {
  _setCheckoutId();
  return ShopifyClient.checkout.addLineItems(_checkoutId, payload);
};

export const updateCart = (payload: Client.AttributeInput[]) => {
  _setCheckoutId();
  return ShopifyClient.checkout.updateLineItems(_checkoutId, payload);
};

export const removeFromCart = (lineItem: string) => {
  _setCheckoutId();
  return ShopifyClient.checkout.removeLineItems(_checkoutId, [lineItem]);
};

export const getSubTotal = (checkout: ShopifyBuy.Cart) => {
  const value: string =
    typeof checkout.subtotalPrice !== "string"
      ? (checkout.subtotalPrice as any).amount
      : checkout.subtotalPrice;
  return parseFloat(value);
};
